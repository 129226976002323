<template>
  <m-sidebar-card
    :scope="scope"
    :on-save="save"
    :on-destroy="destroy"
    :saving="$wait.is('saving workPackage')"
    :destroying="$wait.is('destroying workPackage')"
    :is-new="isNew"
    :title="title"
  >
    <v-text-field
      ref="nameRef"
      v-model="workPackage.name"
      v-validate="{
        required: true,
      }"
      :error-messages="errors.collect('name', scope)"
      data-vv-validate-on="blur"
      name="workPackage.name"
      :label="workPackageNamePlaceholder"
      outlined
    />

    <template v-slot:footer>
      <m-btn-help
        absolute
        :style="{
          right: '24px',
        }"
        class="mt-3"
        @click="() => startTour('MWorkPackageEditor')"
      />
    </template>
  </m-sidebar-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import cloneDeep from 'lodash.clonedeep';
  import { mapWaitingActions } from 'vue-wait';
  import MSidebarCard from './MSidebarCard';
  import { tour } from '../../mixins/tour';

  export default {
    $_veeValidate: { validator: 'new' },

    components: {
      MSidebarCard,
    },

    mixins: [
      tour,
    ],

    data: () => ({
      workPackage: {},
      scope: 'workPackage',
    }),

    computed: {
      ...mapGetters({
        props: 'sidePanel/props',
        workPackageById: 'projectEditor/workPackages/workPackageById',
      }),

      workPackageId() {
        return this.props.workPackageId;
      },

      passageId() {
        return this.props.passageId;
      },

      isNew() {
        return this.workPackageId === null;
      },

      title() {
        if (this.isNew) {
          return this.$pgettext('MWorkPackageEditor component title', 'New work package');
        }

        return this.$pgettext('MWorkPackageEditor component title', 'Edit work package');
      },

      onBeforeDestroy() {
        return this.props.onBeforeDestroy;
      },

      onDestroy() {
        return this.props.onDestroy;
      },

      workPackageNamePlaceholder() {
        return this.$pgettext('MWorkPackageEditor component text input placeholder', 'Work package name');
      },
    },

    mounted() {
      this.initWorkPackage();
    },

    methods: {
      ...mapActions({
        closeSidePanel: 'sidePanel/closeSidePanel',
      }),

      ...mapWaitingActions('projectEditor/workPackages', {
        createWorkPackage: 'saving workPackage',
        updateWorkPackage: 'saving workPackage',
        destroyWorkPackage: 'destroying workPackage',
      }),

      initWorkPackage() {
        if (this.isNew) {
          this.workPackage.passageId = this.passageId;
          return;
        }
        this.workPackage = cloneDeep(this.workPackageById(this.workPackageId));
      },

      async save() {
        const valid = await this.$validator.validateAll(this.scope);

        if (!valid) return;

        if (this.isNew) {
          await this.create();
        } else {
          await this.update();
        }

        this.closeSidePanel();
      },

      async create() {
        const params = {
          workPackage: {
            passageId: this.workPackage.passageId,
            name: this.workPackage.name,
          },
        };

        await this.createWorkPackage(params);
      },

      async update() {
        const params = {
          id: this.workPackage.id,
          payload: {
            workPackage: {
              passageId: this.workPackage.passageId,
              name: this.workPackage.name,
            },
          },
        };

        await this.updateWorkPackage(params);
      },

      async destroy() {
        await this.onBeforeDestroy();

        await this.destroyWorkPackage({
          id: this.workPackage.id,
        });

        this.onDestroy();
        this.closeSidePanel();
      },
    },
  };
</script>
